body {
  direction: rtl;
  text-align: right;
  font-family: 'Tajawal', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
  
  .form-label {
    text-align: right;
  }
  
  .btn {
    font-family: 'Tajawal', sans-serif;
  }
  
  .navbar-nav {
    margin-right: auto;
    margin-left: 0 !important;
  }
  
  .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  
  .navbar-brand {
    margin-right: 0;
    margin-left: 1rem;
  }
  
  .navbar-toggler {
    margin-left: 0;
    margin-right: auto;
  }
  
  /* Container responsiveness */
  .container {
    width: 100%;
    padding-right: var(--spacing-normal);
    padding-left: var(--spacing-normal);
    margin-right: auto;
    margin-left: auto;
  }
  
  /* Responsive tables */
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  /* Responsive images */
  img {
    max-width: 100%;
    height: auto;
  }
  
  /* Responsive navigation */
  @media (max-width: 768px) {
    .navbar-nav {
      margin-right: 0;
      margin-left: 0 !important;
      width: 100%;
    }
  
    .navbar-collapse {
      background-color: var(--primary-color);
      padding: 1rem;
      border-radius: var(--border-radius-normal);
    }
  
    .navbar-toggler {
      margin-left: 0;
      margin-right: auto;
    }
  
    .navbar-brand {
      margin-right: 0;
      margin-left: 0;
    }
  }
  
  /* Responsive forms */
  @media (max-width: 768px) {
    .form-control {
      font-size: var(--font-size-normal);
      padding: var(--spacing-small);
    }
  
    .btn {
      width: 100%;
      margin-bottom: var(--spacing-normal);
    }
  }
  
  /* Responsive cards */
  @media (max-width: 768px) {
    .card {
      margin-bottom: var(--spacing-normal);
    }
  }
  
  /* Mobile-first responsive styles */
  @media (max-width: 768px) {
    body {
      font-size: var(--font-sm);
    }
  
    .container {
      padding-left: var(--space-sm);
      padding-right: var(--space-sm);
    }
  
    .row {
      margin-left: calc(-1 * var(--space-xs));
      margin-right: calc(-1 * var(--space-xs));
    }
  
    .col {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }
  
    .form-control {
      height: 44px; /* Better touch target */
    }
  
    .btn {
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .modal {
      padding: 0 var(--space-sm);
    }
  
    .modal-body {
      padding: var(--space-sm);
    }
  
    .table-responsive {
      margin-bottom: var(--space-md);
    }
  }
  
  /* Base responsive styles */
  :root {
    --mobile-padding: 0.75rem;
    --mobile-font-size: 0.875rem;
    --mobile-heading-size: 1.25rem;
    --header-height: 76px
  }
  
  /* Mobile-first responsive container */
  .container {
    width: 100%;
    padding: var(--mobile-padding);
    margin: 0 auto;
  }
  
  /* Responsive tables wrapper */
  .table-responsive-wrapper {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 1rem;
    background: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)),
                linear-gradient(to left, white 30%, rgba(255, 255, 255, 0)) 100% 0;
    background-size: 50px 100%, 50px 100%;
    background-repeat: no-repeat;
  }
  
  /* Responsive text and headings */
  @media (max-width: 768px) {
    h1 {
      font-size: var(--mobile-heading-size);
      margin-bottom: 1rem;
    }
  
    h2 {
      font-size: calc(var(--mobile-heading-size) * 0.9);
      margin-bottom: 0.75rem;
    }
  
    p, div, span {
      font-size: var(--mobile-font-size);
    }
  
    .table {
      font-size: var(--mobile-font-size);
    }
  
    .btn {
      padding: 0.375rem 0.75rem;
      font-size: var(--mobile-font-size);
    }
  
    .form-control {
      font-size: var(--mobile-font-size);
      padding: 0.375rem 0.75rem;
    }
  }
  
  /* Responsive table adjustments */
  @media (max-width: 576px) {
    .table td, 
    .table th {
      padding: 0.5rem;
      white-space: nowrap;
    }
  
    .btn-group {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  
    .btn-group .btn {
      width: 100%;
      margin: 0;
    }
  }
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  

  main {
    flex: 1;
    position: relative;
    background-image: var(--main-background-image);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
    margin-top: calc(-1 * var(--header-height));
    padding-top: var(--header-height);
    margin-bottom: calc(-1 * var(--footer-height));
    padding-bottom: var(--footer-height);
  }
  

  main::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--main-background-overlay);
    z-index: 0;
  }
  

  .page-content {
    position: relative;
    z-index: 1;
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
    padding: var(--spacing-normal);
  }

  @media (max-width: 768px) {
    main {
      background-attachment: scroll;
    }
    
    .page-content {
      padding: var(--spacing-small);
    }
  }

  .app-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .main-content {
    flex: 1 0 auto;
  }

  footer {
    flex-shrink: 0;
  }

