@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@500;700&display=swap');

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
    position: relative;
    overflow: hidden;
}

.loading-logo {
    width: 200px; /* Adjust this value to match your desired size */
    height: auto;
    z-index: 2;
    position: relative;
}

.wave-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    overflow: hidden;
}

.wave {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: #04111a;
    transition: 0.5s;
}

.wave:nth-child(1) {
    animation: waveRise 3s linear infinite;
    opacity: 0.7;
    z-index: 1000;
}

.wave:nth-child(2) {
    animation: waveRise 3s linear infinite;
    animation-delay: 0.5s;
    opacity: 0.5;
    z-index: 999;
}

@keyframes waveRise {
    0% {
        transform: translateY(100%) scaleY(0);
    }
    100% {
        transform: translateY(0) scaleY(1);
    }
}

.wave::before {
    content: '';
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #04111a;
    border-radius: 100%;
    animation: wave 6s linear infinite;
}

@keyframes wave {
    0% {
        transform: translateX(-100%) scale(0.7);
    }
    100% {
        transform: translateX(100%) scale(0.7);
    }
}

.background-animation {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    background-color: #041520;
    animation: fillBackground 2s ease-out forwards;
}

@keyframes fillBackground {
    0% {
        height: 0;
    }
    100% {
        height: 100%;
    }
}