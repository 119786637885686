.homeBackground {
  min-height: calc(100vh - var(--header-height));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-normal);
}

.homeBackground::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--main-background-overlay);
  z-index: 0;
}

.homeContainer {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: var(--spacing-xlarge);
  background-color: var(--card-background);
  border-radius: var(--border-radius-normal);
  box-shadow: var(--box-shadow-normal);
  border: var(--card-border);
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}
.loginButton {
  display: inline-block;
  background-color: var(--accent-color);
  color: var(--white-color);
  padding: var(--spacing-normal) var(--spacing-large);
  border-radius: var(--border-radius-small);
  text-decoration: none;
  font-weight: 600;
  margin-top: var(--spacing-large);
  transition: var(--transition-normal);
}

.loginButton:hover {
  background-color: var(--accent-color-dark);
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-normal);
}

.welcomeMessage {
  color: var(--white-color);
  margin-bottom: var(--spacing-large);
  font-size: var(--font-size-xxlarge);
  font-weight: 700;
}

.subMessage {
  color: var(--white-color);
  font-size: var(--font-size-large);
  opacity: 0.9;
}

@media (max-width: 768px) {
  .homeContainer {
    padding: var(--spacing-large);
  }

  .welcomeMessage {
    font-size: var(--font-size-xlarge);
  }

  .subMessage {
    font-size: var(--font-size-normal);
  }
}
.actionButtons {
  display: flex;
  gap: var(--spacing-normal);
  justify-content: center;
  margin-top: var(--spacing-large);
}

.actionButton {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-small);
  background-color: var(--accent-color);
  color: var(--white-color);
  padding: var(--spacing-normal) var(--spacing-large);
  border-radius: var(--border-radius-small);
  text-decoration: none;
  font-weight: 600;
  transition: var(--transition-normal);
}

.actionButton:hover {
  background-color: var(--accent-color-dark);
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-normal);
  color: var(--white-color);
  text-decoration: none;
}

.actionButton i {
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .actionButtons {
    flex-direction: column;
  }
  
  .actionButton {
    width: 100%;
    justify-content: center;
  }
}