.fileItem {
    border-bottom: 1px solid #dee2e6;
    padding: 0;
  }
  
  .fileHeader {
    padding: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.2s;
  }
  
  .fileHeader:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
  
  .fileInfo {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .fileName {
    font-weight: 500;
    color: #2c3e50;
  }
  
  .fileMetadata {
    font-size: 0.875rem;
    color: #6c757d;
  }
  
  .fileDetails {
    padding: 1rem;
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
  }
  
  .detailsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 4px;
  }
  
  .detailsGrid > div {
    padding: 0.5rem;
  }
  
  .detailsGrid strong {
    color: #495057;
    margin-right: 0.5rem;
  }
  
  .actions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }
  
  @media (max-width: 576px) {
    .fileInfo {
      font-size: 0.875rem;
    }
    
    .actions {
      flex-direction: column;
    }
    
    .actions button {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .detailsGrid {
      grid-template-columns: 1fr;
    }
  }