.welcome-message {
  color: var(--white-color);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  padding: var(--spacing-xlarge) 0;
}

.welcome-message h1 {
  font-size: var(--font-size-xxlarge);
  font-weight: 700;
  margin-bottom: var(--spacing-normal);
}

.welcome-message p {
  font-size: var(--font-size-large);
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .welcome-message h1 {
    font-size: var(--font-size-xlarge);
  }

  .welcome-message p {
    font-size: var(--font-size-normal);
  }
}