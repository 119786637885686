.header {
  background: var(--primary-color);
  box-shadow: var(--box-shadow-normal);
  padding: var(--spacing-normal) 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-bottom: 1px solid var(--border-color);
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.logoLink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
  transition: var(--transition-normal);
}

.logo:hover {
  transform: scale(1.05);
}

.brandText {
  font-size: var(--font-size-xlarge);
  font-weight: 700;
  color: var(--brand-color) !important;
  margin: 0;
  padding: 0;
  transition: var(--transition-normal);
}

.brandText:hover {
  color: var(--accent-color) !important;
}

.navLink {
  color: var(--text-color) !important;
  font-weight: 600;
  transition: var(--transition-normal);
  padding: var(--spacing-small) var(--spacing-normal);
  border-radius: var(--border-radius-small);
}

.navLink:hover {
  background-color: var(--hover-color);
  color: var(--accent-color) !important;
}

.navbar-toggler {
  border-color: var(--border-color);
  background-color: var(--hover-color);
}

.navbar-toggler-icon {
  background-image: var(--toggler-icon);
}

.controlsContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
}

.themeToggle,
.languageToggle {
  background: none;
  border: 1px solid var(--border-color);
  color: var(--brand-color);
  font-size: 1.2rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: var(--transition-normal);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-small);
}

.themeToggle:hover,
.languageToggle:hover {
  background-color: var(--border-color);
  color: var(--brand-color);
}

@media (max-width: 768px) {
  .header {
    padding: var(--spacing-small) 0;
  }

  .brandText {
    font-size: var(--font-size-large);
  }

  .controlsContainer {
    margin-right: 1rem;
  }
  
  .themeToggle,
  .languageToggle {
    font-size: 1rem;
    padding: 0.3rem;
  }
}

.menuToggle {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuToggleIcon {
  width: 24px;
  height: 2px;
  background-color: var(--text-color);
  position: relative;
  transition: var(--transition-normal);
}

.menuToggleIcon::before,
.menuToggleIcon::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: var(--text-color);
  transition: var(--transition-normal);
}

.menuToggleIcon::before {
  transform: translateY(-8px);
}

.menuToggleIcon::after {
  transform: translateY(8px);
}

.menuToggle:hover .menuToggleIcon,
.menuToggle:hover .menuToggleIcon::before,
.menuToggle:hover .menuToggleIcon::after {
  background-color: var(--accent-color);
}
