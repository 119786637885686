:root {
    /* Color palette */
    --primary-color: #041520;
    --secondary-color: #0a2a3d;
    --background-color: #f8f9fa;
    --text-color: #333333;
    --white-color: #ffffff;
    --light-gray: #6c757d;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --light-gray: #f8f9fa;
    --dark-gray: #343a40;
    --card-background: rgba(4, 21, 32, 0.8) !important;
    --card-border: 1px solid rgba(255, 255, 255, 0.2);
    --card-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  
    /* Gradients */
    --primary-gradient: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
    --primary-color-transparent: rgba(4, 21, 32, 0.8); /* Adjust opacity as needed */

    /* Font sizes */
    --font-size-small: clamp(0.75rem, 2vw, 0.875rem);
    --font-size-normal: clamp(0.875rem, 2.5vw, 1rem);
    --font-size-large: clamp(1rem, 3vw, 1.2rem);
    --font-size-xlarge: clamp(1.25rem, 3.5vw, 1.5rem);
    --font-size-xxlarge: clamp(1.5rem, 4vw, 2rem);
  
    /* Spacing */
    --spacing-small: clamp(0.25rem, 1vw, 0.5rem);
    --spacing-normal: clamp(0.5rem, 2vw, 1rem);
    --spacing-large: clamp(1rem, 3vw, 1.5rem);
    --spacing-xlarge: clamp(1.5rem, 4vw, 2rem);
  
    /* Border radius */
    --border-radius-small: 5px;
    --border-radius-normal: 10px;
    --border-radius-large: 15px;
  
    /* Box shadow */
    --box-shadow-normal: 0 4px 6px rgba(0, 0, 0, 0.1);
    --box-shadow-large: 0 10px 30px rgba(0, 0, 0, 0.2);
  
    /* Transitions */
    --transition-normal: all 0.3s ease;

    /* Responsive breakpoints */
    --mobile-width: 480px;
    --tablet-width: 768px;
    --desktop-width: 1024px;

    /* Breakpoints */
    --mobile: 480px;
    --tablet: 768px;
    --desktop: 1024px;

    /* Spacing */
    --space-xs: clamp(0.25rem, 1vw, 0.5rem);
    --space-sm: clamp(0.5rem, 1.5vw, 1rem);
    --space-md: clamp(1rem, 2vw, 1.5rem);
    --space-lg: clamp(1.5rem, 3vw, 2rem);
    --space-xl: clamp(2rem, 4vw, 3rem);

    /* Typography */
    --font-xs: clamp(0.75rem, 1.5vw, 0.875rem);
    --font-sm: clamp(0.875rem, 1.75vw, 1rem);
    --font-md: clamp(1rem, 2vw, 1.25rem);
    --font-lg: clamp(1.25rem, 2.5vw, 1.5rem);
    --font-xl: clamp(1.5rem, 3vw, 2rem);

    /* Colors */
    --primary: #041520;
    --secondary: #0a2a3d;
    --white: #ffffff;
    --gray-100: rgba(255, 255, 255, 0.1);
    --gray-200: rgba(255, 255, 255, 0.2);

    /* Light theme colors */
    --light-primary-color: #f0f4f8;  /* Light blue-gray */
    --light-secondary-color: #e3eaf2; /* Slightly darker blue-gray */
    --light-text-color: #2c3e50;     /* Dark blue-gray for text */
    --light-accent-color: #3498db;    /* Bright blue for accents */
    --light-border-color: #d1dce7;    /* Blue-tinted border */
    --light-hover-color: #e8f1f8;     /* Light blue hover state */
    --light-brand-color: #041520; 

    /* Dark theme colors (existing) */
    --dark-primary-color: #041520;
    --dark-secondary-color: #0a2a3d;
    --dark-text-color: #ffffff;
    --dark-accent-color: #4dabf7;
    --dark-border-color: rgba(255, 255, 255, 0.2);
    --dark-hover-color: rgba(255, 255, 255, 0.1);
    --dark-brand-color: #f8f9fa;

    /* Dynamic theme variables */
    --primary-color: var(--light-primary-color);
    --secondary-color: var(--light-secondary-color);
    --text-color: var(--light-text-color);
    --accent-color: var(--light-accent-color);
    --border-color: var(--light-border-color);
    --hover-color: var(--light-hover-color);
    --brand-color: var(--light-brand-color);

    /* Add these new variables in the appropriate sections */
    --logo-brightness: 1;
    --toggler-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28var(--text-color-rgb), 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");

    /* Add these new theme variables */
    --overlay-color: rgba(0, 0, 0, 0.5);
    --scrollbar-track-color: var(--light-secondary-color);
    --scrollbar-thumb-color: var(--light-accent-color);
    --scrollbar-thumb-hover-color: #2980b9; /* Darker blue for hover */
    --page-background-image: url('../assets/swaj.jpg');
    --main-background-image: url('../assets/swaj.jpg');
    --main-background-overlay: rgba(0, 0, 0, 0.5);
    --main-background-gradient: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);

    /* Add these new variables */
    --card-background: rgba(255, 255, 255, 0.1);
    --card-border: 1px solid rgba(255, 255, 255, 0.2);
    --card-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    /* Background variables */
    --main-background-image: url('../assets/swaj.jpg');
    --main-background-overlay: rgba(0, 0, 0, 0.5);
    --main-background-color: var(--primary-color);
    --main-background-position: center;
    --main-background-size: cover;
    --main-background-repeat: no-repeat;
    --main-background-attachment: fixed;

    /* Home page variables */
    --accent-color-dark: #0a2a3d;
    --transition-normal: all 0.3s ease;
    --box-shadow-normal: 0 4px 6px rgba(0, 0, 0, 0.1);

    /* Header and Footer variables */
    --header-height: 76px;
    --footer-height: 60px;
    --main-background-overlay: rgba(0, 0, 0, 0.5);
    --main-background-image: url('../assets/swaj.jpg');
}


[data-theme='dark'] {
    --primary-color: var(--dark-primary-color);
    --secondary-color: var(--dark-secondary-color);
    --text-color: var(--dark-text-color);
    --accent-color: var(--dark-accent-color);
    --border-color: var(--dark-border-color);
    --hover-color: var(--dark-hover-color);
    --logo-brightness: 0.9;
    --overlay-color: rgba(0, 0, 0, 0.7);
    --brand-color: var(--dark-brand-color);
    --card-background: rgba(4, 21, 32, 0.8);
}