.admin-page {
  padding: 2rem 0;
  color: white;
}

.admin-page h1,
.admin-page h2 {
  color: white;
  margin-bottom: 2rem;
}
.upload-section {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 0.75rem;
  margin-bottom: 1rem;
}
.table-container {
  background-color: var(--white-color);
  border-radius: 6px;
  overflow-x: auto;
  margin: 0.75rem 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.table {
  width: 100%;
  font-size: 0.875rem;
  background-color: white;
  color: #041520;
}


.table th {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0.5rem;
  font-weight: 600;
  white-space: nowrap;
}

.table td {
  padding: 0.5rem;
  vertical-align: middle;
}


.action-buttons {
  display: flex;
  gap: 0.25rem;
}

.action-buttons button {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
}
.loading-text {
  color: white;
}
@media (max-width: 768px) {
  .admin-page {
    padding: 0.5rem;
  }

  h1, h2 {
    font-size: 1.25rem;
    margin: 0.75rem 0;
  }

  .upload-section {
    padding: 0.5rem;
    margin-bottom: 0.75rem;
  }

  .table th,
  .table td {
    padding: 0.375rem;
    font-size: 0.75rem;
  }

  /* Optimize table columns for mobile */
  .table td:nth-child(3), /* Original Name */
  .table th:nth-child(3) {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .table td:nth-child(2), /* Size */
  .table th:nth-child(2) {
    max-width: 60px;
  }

  .table td:nth-child(4), /* Date */
  .table th:nth-child(4) {
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action-buttons {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .action-buttons button {
    flex: 1;
    min-width: auto;
    padding: 0.25rem;
    font-size: 0.7rem;
  }

  /* Form controls */
  .form-control {
    font-size: 0.875rem;
    padding: 0.375rem;
  }

  /* Pagination */
  .pagination-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    justify-content: center;
    margin-top: 0.5rem;
  }

  .pagination-container button {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }

  .items-per-page {
    width: auto;
    min-width: 80px;
    font-size: 0.75rem;
    padding: 0.25rem;
  }
}

@media (max-width: 480px) {
  .admin-page {
    padding: 0.25rem;
  }

  h1, h2 {
    font-size: 1.1rem;
    margin: 0.5rem 0;
  }

  .table th,
  .table td {
    padding: 0.25rem;
    font-size: 0.7rem;
  }

  .action-buttons button {
    font-size: 0.65rem;
    padding: 0.2rem 0.4rem;
  }
}

@media (max-width: 576px) {
  .admin-container {
    padding: var(--mobile-padding);
  }

  .table-wrapper {
    margin: -0.5rem;
  }

  .table {
    min-width: 600px;
  }

  .action-buttons {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .action-buttons .btn {
    width: 100%;
    margin: 0;
  }

  .form-group {
    margin-bottom: 0.75rem;
  }

  .upload-section {
    padding: 0.75rem;
  }
}

.text-center {
  text-align: center;
}

.py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
