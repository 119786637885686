.employee-card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}

.employee-card:hover {
  transform: translateY(-5px);
}

.employee-card .card-body {
  padding: 1.5rem;
}

.employee-card .card-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 1rem;
}

.employee-card .card-text {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.employee-card .card-text strong {
  font-weight: 600;
  color: #495057;
}

.status-active {
  background-color: #28a745;
  color: white;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 0.8rem;
  display: inline-block;
}

.status-inactive {
  background-color: #dc3545;
  color: white;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 0.8rem;
  display: inline-block;
}
