.login-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  z-index: 1;
  padding-top: var(--header-height);
}

.login-content {
  width: 100%;
  max-width: 500px;
  position: relative;
  z-index: 2;
  padding: var(--spacing-large);
  background-color: transparent !important;
}

.login-card {
  background-color: var(--card-background) !important;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: var(--card-border);
  border-radius: var(--border-radius-normal);
  box-shadow: var(--card-shadow);
  padding: var(--spacing-xlarge);
  position: relative;
  z-index: 1;
}

.login-card :global(.card-body) {
  background-color: transparent !important;
  color: var(--white-color) !important;
}

.logo1 {
  width: 120px;
  height: auto;
  margin-bottom: var(--spacing-large);
  filter: brightness(var(--logo-brightness));
}

.form-label {
  color: var(--text-color);
  font-weight: 600;
  font-size: var(--font-size-normal);
}

.input-group-text {
  background-color: var(--secondary-color);
  border: var(--input-border);
  border-radius: var(--border-radius-small);
  color: var(--white-color);
}

.form-control {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: var(--border-radius-small);
  color: var(--white-color);
  font-size: var(--font-size-normal);
  padding: var(--spacing-medium);
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.form-control:focus {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: var(--accent-color);
  box-shadow: 0 0 0 0.2rem var(--accent-color-transparent);
  color: var(--white-color);
}

.btn-primary {
  background-color: var(--accent-color);
  border: none;
  border-radius: var(--border-radius-small);
  color: var(--white-color);
  font-size: var(--font-size-large);
  font-weight: 600;
  padding: var(--spacing-medium) var(--spacing-large);
  transition: all 0.3s ease;
  width: 100%;
}

.btn-primary:hover, .btn-primary:focus {
  background-color: var(--accent-color-dark);
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-large);
}

@media (max-width: 768px) {
  .login-content {
    max-width: 100%;
    padding: var(--spacing-normal);
  }

  .login-card {
    padding: var(--spacing-large);
  }

  .form-control, .btn-primary {
    font-size: var(--font-size-normal);
    padding: var(--spacing-small) var(--spacing-medium);
  }
}
