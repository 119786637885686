.footer {
  background: var(--primary-color) !important;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.05);
  padding: var(--spacing-normal) 0;
  position: relative;
  z-index: 0;
  border-top: 1px solid var(--border-color);
  flex-shrink: 0;
  margin-top: auto;
}

.footerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.footer p {
  margin: 0;
  color: var(--text-color);
  font-size: var(--font-size-normal);
  text-align: center;
  font-weight: 500;
  opacity: 0.9;
}

.footer a {
  color: var(--accent-color);
  text-decoration: none;
  transition: var(--transition-normal);
}

.footer a:hover {
  color: var(--hover-color);
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer {
    padding: calc(var(--spacing-normal) * 0.75) 0;
  }

  .footer p {
    font-size: calc(var(--font-size-normal) * 0.9);
  }
}