
.search-content {
  width: 100%;
  max-width: min(800px, 95%);
  background-color: var(--card-background);
  border: var(--card-border);
  border-radius: var(--border-radius-normal);
  box-shadow: var(--card-shadow);
  padding: clamp(1rem, 3vw, var(--spacing-xlarge));
  position: relative;
  z-index: 2;
}

.brand-text {
  color: var(--brand-color);
  font-weight: 600;
  transition: var(--transition-normal);
}

.search-title {
  color: var(--white-color) !important;
  font-weight: 700;
  font-size: clamp(1.5rem, 4vw, var(--font-size-xxlarge));
  margin-bottom: var(--spacing-large);
  text-align: center;
}

.search-title {
  color: var(--white-color) !important;
  font-weight: 700;
  font-size: clamp(1.5rem, 4vw, var(--font-size-xxlarge));
  margin-bottom: var(--spacing-large);
  text-align: center;
}
.checkbox-label {
  color: var(--white-color) !important;
  font-size: var(--font-size-normal);
  margin-right: var(--spacing-small);
  user-select: none;
}

/* Style the checkbox container */
.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-normal);
}

/* Style the checkbox input */
.checkbox-container input[type="checkbox"] {
  margin-left: var(--spacing-small);
}

.form-control {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: var(--text-color);
  font-size: clamp(14px, 2vw, var(--font-size-normal));
  padding: clamp(0.5rem, 2vw, var(--spacing-normal));
  height: auto;
  min-height: 44px;
}

.form-control::placeholder {
  color: var(--text-color);
}

.form-control:focus {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: var(--text-color);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
  color: var(--text-color);
}

.loading-text,
.error-text,
.no-results-text {
  color: var(--text-color);
  text-align: center;
  margin-top: var(--spacing-large);
  font-size: clamp(14px, 2vw, var(--font-size-normal));
}

.search-table {
  min-width: 100%;
  color: var(--text-color);
  font-size: clamp(14px, 2vw, var(--font-size-normal));
}

.search-table th,
.search-table td {
  padding: clamp(0.5rem, 1.5vw, 1rem);
  border-color: rgba(255, 255, 255, 0.1);
  white-space: nowrap;
}

.form-check {
  color: var(--text-color) !important;
  margin-top: var(--spacing-small);
  display: flex;
  align-items: center;
}

.form-check input[type="checkbox"] {
  margin-right: var(--spacing-small);
  width: 20px;
  height: 20px;
}

/* Mobile-first search results */
.search-results-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.ticket-card {
  background-color: var(--primary-color);
  border-radius: var(--border-radius-normal);
  box-shadow: var(--box-shadow-normal);
  margin-bottom: var(--spacing-normal);
  transition: var(--transition-normal);
  padding: var(--spacing-large);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ticket-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--box-shadow-large);
}

.ticket-title {
  font-size: var(--font-size-large);
  font-weight: bold;
  color: var(--accent-color);
  margin-bottom: var(--spacing-normal);
}

.ticket-text {
  margin-bottom: var(--spacing-small);
  font-size: var(--font-size-normal);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-color);
}

.ticket-label {
  font-weight: 600;
  color: var(--text-color);
  margin-left: var(--spacing-small);
  min-width: 100px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .ticket-card {
    padding: 1rem;
  }
  
  .ticket-text {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .ticket-label {
    margin-bottom: 0.25rem;
    margin-left: 0;
  }
}

/* Match status styles */
.exact-match {
  border: 2px solid var(--success-color);
}

/* Responsive table */
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 1rem -1rem;
  padding: 0 1rem;
}

/* Media Queries */
@media (max-width: 768px) {
  .search-content {
    margin: 1rem;
  }
  
  .ticket-card {
    margin: 0.5rem 0;
  }
}

@media (max-width: 480px) {
  .search-content {
    padding: 1rem;
  }
  
  .form-check {
    margin: 1rem 0;
  }
  
  .ticket-text {
    flex-direction: column;
  }
  
  .ticket-label {
    margin-bottom: 0.25rem;
  }
}

/* Add these new styles */
.search-results-wrapper {
  display: flex;
  gap: 2rem;
  margin-top: var(--spacing-large);
  min-height: 400px; /* Minimum height for sections */
}

.search-results-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--card-background);
  border-radius: var(--border-radius-normal);
  box-shadow: var(--card-shadow);
  padding: 1.5rem;
  position: relative;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.section-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--white-color);
  margin: 0;
}

.results-count {
  background: rgba(255, 255, 255, 0.1);
  color: var(--white-color);
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
}

.results-container {
  flex: 1;
  overflow-y: auto;
  padding-right: 0.5rem; /* Space for scrollbar */
}

/* Custom scrollbar for results container */
.results-container::-webkit-scrollbar {
  width: 6px;
}

.results-container::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
  border-radius: var(--border-radius-small);
}

.results-container::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: var(--border-radius-small);
}

.results-container::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover-color);
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  color: var(--white-color);
  opacity: 0.7;
}

.empty-state-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.empty-state-text {
  font-size: 0.9rem;
}

/* Update existing card styles */
.search-results-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 0.5rem;
}

.ticket-card,
.employee-card {
  height: auto;
  margin: 0;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.ticket-card:hover,
.employee-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 992px) {
  .search-results-wrapper {
    flex-direction: column;
    gap: 1.5rem;
  }

  .search-results-section {
    max-height: 500px;
  }
}

@media (max-width: 768px) {
  .search-results-wrapper {
    margin-top: 1rem;
  }

  .search-results-section {
    padding: 1rem;
  }

  .section-header {
    margin-bottom: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .section-title {
    font-size: 1.1rem;
  }
}