.sideMenu {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100vh;
  background-color: var(--primary-color);
  z-index: 1001;
  transition: transform 0.3s ease-in-out;
  padding: var(--spacing-large);
  box-shadow: var(--box-shadow-large);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform: translateX(0);
}

.open {
  transform: translateX(300px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--overlay-color);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.overlayVisible {
  opacity: 1;
  visibility: visible;
}

.closeButton {
  position: absolute;
  top: var(--spacing-normal);
  right: var(--spacing-normal);
  background: none;
  border: none;
  font-size: var(--font-size-xxlarge);
  color: var(--text-color);
  cursor: pointer;
  transition: var(--transition-normal);
  padding: var(--spacing-small);
  z-index: 1002;
}

.closeButton:hover {
  color: var(--accent-color);
  transform: scale(1.1);
}

.menuItems {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-normal);
  flex: 1;
}

.menuItems a {
  color: var(--text-color) !important;
  font-size: clamp(1rem, 4vw, var(--font-size-large));
  padding: clamp(0.5rem, 2vh, var(--spacing-normal)) 0;
  text-align: right;
  transition: var(--transition-normal);
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--spacing-small);
}

.menuItems a:hover {
  color: var(--accent-color) !important;
  background-color: var(--hover-color);
  border-radius: var(--border-radius-small);
  padding-right: var(--spacing-small);
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-normal);
  border-bottom: 1px solid var(--border-color);
  margin: 0 calc(-1 * var(--spacing-normal));
  margin-bottom: var(--spacing-large);
  margin-top: calc(var(--spacing-xlarge) + 1rem);
}

@media (max-width: 768px) {
  .sideMenu {
    left: -100%;
    width: 100%;
    transform: translateX(0);
  }

  .open {
    transform: translateX(100%);
  }

  .menuItems a {
    padding: calc(var(--spacing-normal) * 1.2) var(--spacing-small);
    border-bottom: 1px solid var(--border-color-light);
  }

  .controls {
    padding: var(--spacing-small);
    flex-wrap: wrap;
    gap: var(--spacing-small);
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .sideMenu {
    left: -350px;
    width: 350px;
    transform: translateX(0);
  }

  .open {
    transform: translateX(350px);
  }
}

@supports (padding: max(0px)) {
  .sideMenu {
    padding-left: max(var(--spacing-large), env(safe-area-inset-left));
    padding-right: max(var(--spacing-large), env(safe-area-inset-right));
    padding-top: max(var(--spacing-large), env(safe-area-inset-top));
    padding-bottom: max(var(--spacing-large), env(safe-area-inset-bottom));
  }
}
.themeSwitcher {
  position: relative;
  width: 64px;
  height: 32px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  padding: 2px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin: var(--spacing-normal) 0;
  overflow: hidden;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}


.switcherIcons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  pointer-events: none;
}

.sunIcon,
.moonIcon {
  font-size: 14px;
  transition: all 0.3s ease;
  z-index: 1;
}

.sunIcon {
  color: #f1c40f;
}

.moonIcon {
  color: #f1c40f;
}

.switcherHandle {
  width: 24px;
  height: 24px;
  background: var(--accent-color);
  border-radius: 50%;
  position: absolute;
  left: 4px;
  top: 2px;
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.switcherHandle.dark {
  transform: translateX(28px);
}


.themeSwitcher:hover {
  border-color: var(--accent-color);
  box-shadow: 0 0 15px rgba(var(--accent-color-rgb), 0.2);
}

.themeSwitcher:hover .switcherHandle {
  box-shadow: 0 0 8px var(--accent-color);
}

@media (max-width: 768px) {
  .themeSwitcher {
    width: 56px;
    height: 28px;
  }

  .switcherHandle {
    width: 20px;
    height: 20px;
  }

  .switcherHandle.dark {
    transform: translateX(24px);
  }
}

.languageSelector {
  position: relative;
  width: 80px;
  height: 36px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  padding: 3px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.languageButton {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--text-color);
  font-weight: 600;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 16px;
  transition: all 0.3s ease;
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.languageButton:hover {
  color: var(--accent-color);
}

.languageIcon {
  font-size: 16px;
  color: var(--accent-color);
  transition: transform 0.3s ease;
}

.languageSelector:hover .languageIcon {
  transform: rotate(180deg);
}

.languageDropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 50%;
  transform: translateX(50%) translateY(-10px);
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  z-index: 1000;
  min-width: 140px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}


.languageDropdown.show {
  opacity: 1;
  visibility: visible;
  transform: translateX(50%) translateY(0);
}


.languageOption {
  padding: 10px 20px;
  color: var(--text-color);
  transition: all 0.3s ease;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  position: relative;
}
.languageOption:hover {
  background: rgba(var(--accent-color-rgb), 0.2);
  color: var(--accent-color);
}


.languageSelector:hover {
  border-color: var(--accent-color);
  box-shadow: 0 0 15px rgba(var(--accent-color-rgb), 0.3);
}
.languageOption:not(:last-child)::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10%;
  width: 80%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  .languageSelector {
    width: 70px;
    height: 32px;
  }
  
  .languageButton {
    font-size: 12px;
  }
}